@import './app/styles/buttons.less';
@import './app/styles/inputs.less';
@import './app/styles/currencies.less';
@import './app/styles/page.less';

// BREAKPOINTS

:root {
  --tablet: 744px;
  --mobile: 360px;
}

html {
  overflow: auto;
}

body,
h1,
h2,
h3,
p,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

ul,
ol {
  list-style: none;
}

* {
  box-sizing: border-box;
}

body {
  direction: rtl;
  font-family: 'Arimo', sans-serif;
  color: var(--textColor);
  background-color: var(--secondBgColor);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(104, 100, 100, 0.8);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(177, 176, 176);
  border-radius: 10px;

  &:hover {
    background-color: rgb(148, 147, 147);
  }

  &:active {
    background-color: rgb(126, 125, 125);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.responsive {
  max-width: 100%;
  max-height: 100%;
}

.form-group,
.middle-form-group {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.form-control {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  input,
  textarea {
    border-color: var(--primaryColor);
  }

  .helper {
    color: var(--primaryColor);
    font-size: 1rem;
  }
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  overflow: hidden !important;
  color: inherit !important;
  background-color: var(--secondBgColor);
}

.bg-transparent {
  .mat-dialog-container {
    background-color: transparent;
  }
}

.terms-container {
  max-width: 100vw !important;

  .mat-dialog-container {
    margin: auto;
    max-width: 100vw !important;
  }
}

// contact pages
bi-suggest-improvments,
bi-contact-bringit,
bi-contact-pizziria {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;

  @media screen and (max-width: 767px) {
    .page-container {
      padding: 50px;
    }

    .page-container.suggestions {
      height: auto;
    }
  }

  @media screen and (max-width: 500px) {
    .page-container {
      padding: 10px !important;
    }
  }
}

// for extra large devices
@media screen and (min-width: 1921px) {
  body {
    background-color: var(--primaryBgColor);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .form-group {
    display: flex;

    .form-control {
      flex: 1;
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-left: 40px;
      }
    }
  }

  .middle-form-group {
    width: 50%;
    margin: 0 auto 20px;

    .form-control {
      flex: 1;
    }
  }
}

/* wide screen with small height */
@media only screen and (min-width: 1200px) and (max-height: 700px) {
  .form-group,
  .middle-form-group {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  .form-control {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .helper {
      font-size: 0.7rem;
    }
  }
}

/*
 * Remove google chrome autocomplete fill
 * https://stackoverflow.com/questions/55131944/how-to-remove-blue-background-on-chrome-autocomplete
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  -webkit-background-clip: text;
  background-clip: text;
}

.coupon-info-modal {
  width: 400px;

  .bi-btn {
    font-size: 16px;
  }

  div.info-modal-content {
    width: auto;
  }
}

.main-header {
  z-index: 9999999999 !important;
}

// .box.product {
//   width: 270px !important;
//   height: 240px !important;
//   border-radius: 5px;
//   border: solid 1px var(--primaryColorDark);
//   overflow: revert;
//   margin-bottom: 60px;
//   padding: 0;
//   text-align: center;
// }

// .box.product > bi-additional-offer > .item-container.item-padding {
//   padding: 0 !important;
//   position: relative;
//   flex-direction: column;
// }
// @media only screen and (max-width: 500px) {
//   .box.product {
//     width: 160px !important;
//     height: 160px !important;
//     margin: 0 auto 20px;
//     .bi-btn {
//       display: none;
//     }

//     .price.shekel {
//       height: 34px;
//       max-width: 70px;
//       font-size: 14px !important;
//     }

//     .img-container {
//       margin-left: 0;
//       height: 78px !important;
//     }

//     .item-details {
//       padding: 0 !important;
//     }
//   }
// }
