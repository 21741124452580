.bi-btn {
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--primaryColor);
  color: white;
  font-size: 1em;
  transition: background-color 0.2s;

  &.full {
    width: 100%;
    max-width: 230px;
  }
  &.bi-btn-ghost {
    background-color: initial;
    color: var(--secondTextColor);
    border-color: var(--primaryColor);
  }
  &.bi-btn-dismiss {
    background-color: #fff;
    color: var(--primaryColor);
  }

  &.bi-sm {
    font-size: 1rem;
  }
  &.bi-btn-scondary {
    background-color: var(--secondBtnColor);
    color: #fff;
  }
}

/* hover */
.bi-btn:hover:not(:disabled) {
  background-color: var(--primaryColorDark);

  &.bi-btn-dismiss {
    background-color: #f2faf9;
  }

  &.bi-btn-dismiss {
    background-color: #dde0ee;
  }

  &.bi-btn-scondary {
    background-color: var(--secondBtnColorDarker);
  }
}

/* disabled */
.bi-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

/* outline */
.bi-btn:active,
.bi-btn:focus {
  outline: none;
}

.out-of-stock {
  .bi-btn {
    opacity: 0.5;
    cursor: default;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bi-btn {
    font-size: 1rem;
  }
}

/* wide screen with small height */
@media only screen and (min-width: 1200px) and (max-height: 700px) {
  .bi-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
  }
}
