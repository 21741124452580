input,
textarea {
  background-color: initial;
  padding: 10px;
  border: 2px solid var(--primaryColor);
  border-radius: 10px;
  width: 100%;
  color: var(--textColor);
  font-size: 1rem;

  &:focus {
    box-shadow: 0px 0px 4px 1px var(--primaryColor);
    outline: var(--primaryColor);
  }
}
.checkbox-core {
  input[type='checkbox'] {
    display: none;
    &:checked + label:after {
      content: '';
      position: absolute;
      border: solid var(--primaryColor);
      width: 9px;
      height: 20px;
      border-width: 0 3px 3px 0;
      transform: translate(0, -5px) rotate(45deg);
    }
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    &:before {
      content: '**';
      color: transparent;
      display: inline-block;
      position: relative;
      border-radius: 2px;
      border: 1px solid var(--primaryColor);
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
}
textarea {
  resize: none;
  height: 100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* wide screen with small height */
@media only screen and (min-width: 1200px) and (max-height: 700px) {
  input,
  textarea {
    padding: 8px;
    border-width: 1px;
    font-size: 0.8rem;
  }
  textarea {
    height: 80px;
  }
}


