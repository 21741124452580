.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  //! temporary disabled -> need to remove padding if no bugs will be founded in the future
  // padding: 0 10px;
  overflow: hidden;

  .page-title {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 1rem;
    color: var(--titleColor);
    text-align: center;
  }

  .content-container {
    flex: 1;
    width: 100%;
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
  }
}
.scrollable {
  overflow: auto;
}

.text-center {
  text-align: center !important;
}

.box,
.box-padding {
  background: var(--boxBgColor);
  border: 1px solid var(--primaryColor);
  border-radius: 20px;
  overflow: hidden;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  &.out-of-stock {
    border: none !important;
    .price.shekel {
      top: 0;
      right: 0 !important;
    }
  }
}
.box-padding,
.item-padding {
  padding: 10px;
}
bi-add-deal {
  bi-additional-offer {
    height: 100% !important;
    overflow: hidden;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page-container {
    //padding-left: var(--pageSpacing);
    //padding-right: var(--pageSpacing);
    max-width: 1170px;
    margin: 0 auto;

    .page-title {
      font-size: 1rem;
    }
  }
  .box,
  .box-padding {
    border: none;
    margin-right: 1rem;
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }
  .box-padding,
  .item-padding {
    padding: 20px;
  }
}

.error-text {
  color: var(--errorColor);
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* wide screen with small height */
@media only screen and (min-width: 1200px) and (max-height: 700px) {
  .page-container {
    .page-title {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  bi-add-deal {
    .page-title {
      display: none;
    }
  }
  .box-padding,
  .item-padding {
    padding: 10px;
    position: relative;
  }
  .text-center.form-title h1 {
    font-size: 15px !important;
  }
}

.fixed-body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
